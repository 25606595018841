<template>
    <div class="page-rooter-fff">
        <headBar ref="header" :background="'transparent'" title="发送卡券红包" left-arrow @click-left="newAppBack" />
<!--        <nav-bar header-title="发送卡券红包" :show-back="true" :header-back="newAppBack" />-->
        <div class="top-box">
            <div class="give-title row-between-center">
                <p class="row-start-center">选择要送的卡券 <span>已选{{ selectedList.length }}张</span></p>
                <div v-if="!recordId" @click="show = true" class="row-center-center"><van-icon v-show="!selectedList.length" style="font-weight: bold" name="plus" />{{selectedList.length? '修改': '添加'}}</div>
            </div>
            <div class="card-box">
                <div v-if="selectedList.length" class="card-list">
                    <div class="card-item row-start-start" v-for="item in selectedList" :key="item.equityCardGiftId">
                        <img :src="item.iconUrl" alt="">
                        <div>
                            <h4>{{ item.equityCardName }}</h4>
                            <p>有效期：{{ item.useValidity }}</p>
                        </div>
                    </div>
                </div>
                <div v-else class="empty row-center-center">红包还空着，先去添加一些卡券吧~</div>
            </div>
        </div>
        <div @click="showBag = true" class="red-bag row-between-center">
            <div>
                <h4>定制祝福</h4>
                <p>{{ bagText }}</p>
            </div>
            <div class="row-center-center">
                <img :src="resultBgItem.backgroundImgUrl" alt="">
                <van-icon name="arrow" />
            </div>
        </div>
        <div class="tips row-center-center">赠送给您的微信好友，好友会领取红包内全部卡券。</div>
        <div class="bottom-btn">
            <p @click="jumpRecord" class="row-center-center">赠礼记录</p>
            <div>
                <div :style="selectedList.length? '': 'filter: grayscale(100%)'" @click="giving" class="col-center-center">
                    <p>红包已包好 送给Ta</p>
                    <span>发出后24小时内未被领取则自动退回您的账户</span>
                </div>
            </div>
        </div>
        <SelectCard :selectedList="selectedList" :show.sync="show" @confirm="cardConfirm"></SelectCard>
        <SelectBg :text="bagText" :resultBgItem="resultBgItem" :list="bgList" :show.sync="showBag" @confirm="bgConfirm"></SelectBg>
        <!-- 微信、小程序右上角分享提示 -->
        <van-overlay :show="isShareWx" :z-index="1500" @click="closeShareBox">
            <div class="tip-box" @click.stop>
                <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/anniversary2021/share-mini.png" class="tip-img"/>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import {enterForeground, initBack, newAppBack, webAppShare} from '@/lib/appMethod';
import userMixin from '@/mixin/userMixin';
import headBar from "@/components/app/headBar";
import {getParam} from "@/lib/utils";
import SelectCard from './components/select-card'
import SelectBg from './components/select-bg'
import wx from "weixin-js-sdk";
export default {
    components: {
        headBar,
        SelectCard,
        SelectBg
    },
    mixins: [userMixin],
    data() {
        return {
            show: false,
            showBag: false,
            isShareWx: false,
            cardList: [],
            selectedList: [], // 选中的卡
            bgList: [], // 红包背景
            resultBgItem: {}, // 选中背景
            bagText: '让我请你去超鹿运动吧', // 赠言
            shareParams: {
                title: '『超鹿运动』按次付费的健身房！快来约课吧',
                miniImage: '',
                path: '/pages/home/index',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1
                    ? '0'
                    : '2', // 正式0，开发1，体验2
                url: 'url',
                multiple: "0.95",
                userName: "gh_7f5b57b6a76e",
            },
            recordId: '',
            type: 'EQUITY_CARD_SELF_BUY', // 赠送卡来源
        }
    },
    async created() {
        initBack();
        await this.$getUserId();
        if(this.appTypeStr === 'mini'){
            wx.miniProgram.postMessage({
                data: {
                    type: 'share',
                    shareImage: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/mini-share-202108.png',
                    shareTitle: '『超鹿运动』按次付费的健身房！快来约课吧',
                    shareUrl: '/pages/home/index',
                }
            });
        }
        this.recordId = this.$route.query.recordId
        if(this.recordId){
            this.setCard()
        }
        if(this.$route.query.showList){
            this.show = true
            this.$route.query.showList = null
        }
    },
    methods: {
        newAppBack,
        bgConfirm(item, bagText) {
            console.log(this.userId, this.token)
            console.log(item, bagText)
            this.resultBgItem = item
            this.bagText = bagText
        },
        cardConfirm(v) {
            this.selectedList = v
            console.log(v)
        },
        // 从续费记录里加的卡 设置选中项
        setCard() {
            this.$axios.post(`${this.baseURLApp}/user/giving/cardVoucher/list/v1`, {
                token: this.token,
                userId: this.userId,
                equityCardUserIdList: [this.recordId]
            }).then(res => {
                this.selectedList = res.data.equityCardGiftList || []
            })
        },
        jumpRecord() {
            this.$router.push({
                path: `/give-equity-card/record?userId=${this.userId}&token=${this.token}`,
            })
        },
        giving() {
            if(!this.selectedList.length) {
                this.$toast('请选择要赠送卡券！')
                return false
            }
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            let cardVoucherList = this.selectedList.map(v => {
                return {id: v.equityCardGiftId, type: this.type}
            })
            this.$axios.post(`${this.baseURLApp}/user/giving/record/add`, {
                token: this.token,
                userId: this.userId,
                cardVoucherList,
                givingWords: this.bagText,
                redPacketId: this.resultBgItem.id,
            }).then(res => {
                setTimeout(() => {this.selectedList = []}, 2000)
                this.$toast.clear()
                this.shareMini(res.data)
            }).catch(r => {
                this.$toast.clear()
            })
        },
        shareMini(id){
            if(this.appTypeStr === 'mini'){
                wx.miniProgram.navigateTo({
                    url: `/pages/activity/receivingGiftShare/index?shareId=${id}`
                })
            }else{
                let miniParams = JSON.parse(JSON.stringify(this.shareParams))
                miniParams.image = this.resultBgItem.coverImgUrl
                miniParams.title = this.bagText
                miniParams.path = `/pages/activity/receivingGift/index?shareId=${id}`
                webAppShare('3', miniParams)
                enterForeground(() => {
                  this.jumpRecord()
                  enterForeground(() => {})
                })
            }
        },
        closeShareBox(){
            this.isShareWx = false
            wx.miniProgram.postMessage({
                data: {
                    type: 'share',
                    shareImage: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/mini-share-202108.png',
                    shareTitle: '『超鹿运动』按次付费的健身房！快来约课吧',
                    shareUrl: '/pages/home/index',
                }
            });
        },
    }
}
</script>

<style scoped lang="less">
    .page-rooter-fff{
        background: linear-gradient(180deg, #FFE3CE 0%, rgba(255,227,206,0) 100%) no-repeat #F9FAFB;
        background-size: 750px 492px;
        min-height: 100vh;
        .top-box{
            width: 686px;
            background: #FF6E00;
            margin: 24px auto;
            border-radius: 16px;
            padding-bottom: 8px;
            .give-title{
                padding: 24px 32px;
                p{
                    line-height: 28px;
                    font-size: 28px;
                    color: #FFFFFF;
                    font-weight: bold;
                    span{
                        background: rgba(0,0,0,0.2);
                        border-radius: 4px;
                        padding: 8px 12px;
                        line-height: 20px;
                        font-size: 20px;
                        margin-left: 12px;
                    }
                }
                div{
                    width: 110px;
                    height: 44px;
                    background: #FFDE00;
                    border-radius: 4px;
                    color: #242831;
                    font-weight: bold;
                    font-size: 24px;
                }
            }
            .card-box{
                margin: 0 auto;
                width: 670px;
                background: #FFFFFF;
                min-height: 208px;
                border-radius: 12px;
                max-height: 500px;
                overflow-y: auto;
                padding: 16px 32px;
                box-sizing: border-box;
                .card-list{
                    padding: 16px 0;
                    .card-item{
                        &+.card-item{
                            margin-top: 24px;
                        }
                        img{
                            width: 128px;
                            height: 128px;
                        }
                        &>div{
                            padding: 0 20px 0 24px;
                            border: 1px solid #EEEEEE;
                            border-radius:0 12px 12px 0;
                            border-left: none;
                            flex-grow: 1;
                            margin-left: -4px;
                            h4{
                                color: #242831;
                                font-size: 24px;
                                padding-top: 16px;
                                line-height: 24px;
                            }
                            p{
                                margin-top: 20px;
                                font-size: 20px;
                                line-height: 20px;
                                color: #3C454E;
                            }
                        }
                    }
                }
                .empty{
                    height: 208px;
                    color: #6C727A;
                    font-size: 22px;
                }
            }
        }
        .red-bag{
            width: 686px;
            height: 184px;
            background: #FFFFFF;
            border-radius: 16px;
            margin: 0 auto;
            padding: 0 32px;
            box-sizing: border-box;
            div{
                &:first-child{
                    h4{
                        font-size: 28px;
                        line-height: 28px;
                        color: #242831;
                    }
                    p{
                        line-height: 24px;
                        font-size: 22px;
                        color: #6C727A;
                        margin-top: 44px;
                    }
                }
                &:last-child{
                    color: #9AA1A9;
                    img{
                        width: 80px;
                        height: 104px;
                        background: #D9D9D9;
                        border-radius: 6px;
                        margin-right: 16px;
                    }
                }
            }
        }
        .tips{
            width: 686px;
            height: 72px;
            background: #FFF3EB;
            border-radius: 16px;
            margin: 24px auto 0;
            color: #FF6E00;
            font-size: 24px;
        }
        .bottom-btn{
            position: fixed;
            bottom: 0;
            left: 0;
            &>p{
                width: 168px;
                height: 60px;
                border-radius: 30px;
                border: 1px solid #242831;
                box-sizing: border-box;
                color: #242831;
                font-size: 26px;
                margin: 0 auto 44px;
            }
            &>div{
                width: 750px;
                height: calc(152px + env(safe-area-inset-bottom));
                background: #FFFFFF;
                padding: 28px 32px calc(env(safe-area-inset-bottom) + 28px);
                box-sizing: border-box;
                &>div{
                    width: 100%;
                    height: 100%;
                    background: #FFDE00;
                    border-radius: 8px;
                    &.gray{
                        opacity: 0.35;
                    }
                    p{
                        line-height: 28px;
                        font-size: 28px;
                        font-weight: bold;
                        color: #242831;
                    }
                    span{
                        margin-top: 12px;
                        color: #3C454E;
                        line-height: 20px;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .tip-box {
        position: relative;
        .tip-img {
            position: absolute;
            top: 7px;
            right: 130px;
            width: 300px;
            z-index: 99999;
            display: block;
        }
    }
</style>
